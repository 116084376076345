import axios from './http'

// 订单列表
const orderInfoList = (params) => {
  return axios({
    method: 'get',
    url: '/ParkingLotApi/Order/GetPageList',
    params
  })
}
// 订单列表--金额查询
const getStatisticsInfo = (params) => {
  return axios({
    method: 'get',
    url: '/ParkingLotApi/Order/GetStatisticsInfo',
    params
  })
}
// 订单列表--子母订单
const getListByLetterOrder = (params) => {
  return axios({
    method: 'get',
    url: '/ParkingLotApi/Order/GetListByLetterOrder',
    params
  })
}
// 查看订单详情
const orderDetail = (params) => {
  return axios({
    method: 'get',
    url: '/ParkingLotApi/Order/OrderDetail',
    params
  })
}
// 异常订单列表
const abnormalOrderList = (params) => {
  return axios({
    method: 'get',
    url: 'ParkingLotApi/AbnormalOrder/GetPageList',
    params
  })
}
// 配置异常规则
const abnormalOrderConfig = (data) => {
  return axios({
    method: 'post',
    url: '/ParkingLotApi/AbnormalRule/Add',
    data
  })
}
// 异常规则详情接口
const abnormalOrderDetails = (params) => {
  return axios({
    method: 'get',
    url: '/ParkingLotApi/AbnormalRule/Detaile',
    params
  })
}
// 审核异常订单
const abnormalAudit = (data) => {
  return axios({
    method: 'post',
    url: '/ParkingLotApi/AbnormalOrder/AbnormalAudit',
    data
  })
}
// 审核/查看异常订单页面
const abnormalDetial = (params) => {
  return axios({
    method: 'get',
    url: '/ParkingLotApi/AbnormalOrder/AbnormalView',
    params
  })
}
// 订单申诉列表
const orderApplyList = (params) => {
  return axios({
    method: 'get',
    url: '/ParkingLotApi/AppealOrder/GetPageList',
    params
  })
}
// 审核申诉订单
const appealAudit = (data) => {
  return axios({
    method: 'post',
    url: '/ParkingLotApi/AppealOrder/AppealAudit',
    data
  })
}
// 申请退款
const applyRefund = (data) => {
  return axios({
    method: 'post',
    url: '/ParkingLotApi/Order/ApplyRefund',
    data
  })
}
// 退款记录接口
const refundRecordList = (params) => {
  return axios({
    method: 'get',
    url: '/ParkingLotApi/ParkingOrderRefundInfo/GetPageList',
    params
  })
}
// 退款详情接口
const refundDetail = (params) => {
  return axios({
    method: 'get',
    url: '/ParkingLotApi/ParkingOrderRefundInfo/Detail',
    params
  })
}
// 获取订单申诉详情接口
const appealOrderDetail = (params) => {
  return axios({
    method: 'get',
    url: '/ParkingLotApi/AppealOrder/Detail',
    params
  })
}
export default {
  orderInfoList,
  getStatisticsInfo,
  getListByLetterOrder,
  orderDetail,
  abnormalOrderList,
  abnormalOrderConfig,
  abnormalAudit,
  orderApplyList,
  appealAudit,
  abnormalOrderDetails,
  applyRefund,
  refundRecordList,
  refundDetail,
  appealOrderDetail,
  abnormalDetial
}
