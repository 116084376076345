<template>
  <!-- 订单管理 —— 订单信息 -->
  <div>
    <div class="box-card">
      <!-- 主要内容 -->
      <el-form label-width="180px" :model="tableData" class="detailForm">
        <div class="title">基本信息</div>
        <el-divider></el-divider>
        <el-row :gutter="20">
          <el-col :span="8" style="text-align: left">
            <el-form-item label="车牌号：">{{
              tableData.plate_number
            }}</el-form-item>
            <el-form-item label="停车场：">{{
              tableData.parking_name
            }}</el-form-item>
            <el-form-item label="类型：">
              {{
                getWayName("CarOwnerCardType", tableData.car_owner_card_type)
              }}</el-form-item>
            <el-form-item label="入场时间：">{{
              validDateTime(tableData.go_in_time)
            }}</el-form-item>
            <el-form-item label="出场时间：">{{
              validDateTime(tableData.go_out_time)
            }}</el-form-item>
            <el-form-item label="停车时长：">{{
              tableData.parking_time_length
              ? getTimeLength(tableData.parking_time_length)
              : ""
            }}</el-form-item>
            <el-form-item label="订单来源：">{{
              getWayName("OrderSource", tableData.order_source)
            }}</el-form-item>
            <el-form-item label="订单生成时间：">{{
              validDateTime(tableData.create_time)
            }}</el-form-item>
            <el-form-item label="应收金额：">￥{{
              tableData.calculate_cost
              ? tableData.calculate_cost.toFixed(2)
              : "0.00"
            }}</el-form-item>
            <el-form-item label="优惠金额：">￥{{
              tableData.discount_amount
              ? tableData.discount_amount.toFixed(2)
              : "0.00"
            }}</el-form-item>
            <el-form-item label="实收金额：">￥{{
                tableData.final_cost ?
                tableData.final_cost.toFixed(2)
                : "0.00"
              }}</el-form-item>

          </el-col>
          <el-col :span="8" style="text-align: left">
            <el-form-item label="支付方式：">{{
              tableData.payment_method === 0
              ? "/"
              : getWayName("PaymentMethod", tableData.payment_method)
            }}</el-form-item>
            <el-form-item label="支付时间：">{{
              validDateTime(tableData.payment_time)
            }}</el-form-item>

            <el-form-item label="订单状态：">{{
              tableData.order_status
              ? $store.getters.getWayName(
                "OrderStatus",
                tableData.order_status
              )
              : ""
            }}</el-form-item>
            <el-form-item label="优惠券：" v-if="tableData.coupon_name">{{
              tableData.coupon_name
            }}</el-form-item>
            <template v-if="tableData.order_status === 4">
              <el-form-item label="退款操作：">{{
                tableData.refund_Name
              }}</el-form-item>
              <el-form-item label="退款时间：">{{
                validDateTime(tableData.actual_refund_time)
              }}</el-form-item>
              <el-form-item label="退款金额：">￥{{ tableData.actual_refund_cost.toFixed(2) }}</el-form-item>
              <el-form-item label="退款原因：">{{
                tableData.apply_refund_reason
              }}</el-form-item>
            </template>
          </el-col>
          <el-col :span="8" style="text-align: left">
            <el-carousel :autoplay="false" style="height: 200px" v-if="formatImage(tableData.go_in_image).length > 1">
              <el-carousel-item v-for="(item, index) in formatImage(tableData.go_in_image)" :key="index">
                <el-image class="in-out-image" fit="cover" :src="item"
                  :preview-src-list="formatImage(tableData.go_in_image)" :z-index="9999">
                  <div slot="error" class="image-slot">
                    <i class="iconfont lebo-picerr"></i>
                    <span>加载失败</span>
                  </div>
                </el-image>
              </el-carousel-item>
            </el-carousel>
            <div v-else-if="formatImage(tableData.go_in_image).length === 1">
              <el-image class="in-out-image" fit="cover" v-for="(item, index) in formatImage(tableData.go_in_image)"
                :key="index" :src="item" :preview-src-list="formatImage(tableData.go_in_image)" :z-index="9999">
                <div slot="error" class="image-slot">
                  <i class="iconfont lebo-picerr"></i>
                  <span>加载失败</span>
                </div>
              </el-image>
            </div>
            <div v-else class="image-slot">
              <span>暂无入场图片</span>
            </div>
            <div class="maskBox">入场图片</div>
            <el-carousel :autoplay="false" style="height: 200px" v-if="formatImage(tableData.go_out_image).length > 1">
              <el-carousel-item v-for="(item, index) in formatImage(tableData.go_out_image)" :key="index">
                <el-image class="in-out-image" :src="item" :preview-src-list="formatImage(tableData.go_out_image)"
                  fit="cover" :z-index="9000">
                  <div slot="error" class="image-slot">
                    <i class="iconfont lebo-picerr"></i>
                    <span>加载失败</span>
                  </div>
                </el-image>
              </el-carousel-item>
            </el-carousel>
            <div v-else-if="formatImage(tableData.go_out_image).length === 1">
              <el-image class="in-out-image" fit="cover" v-for="(item, index) in formatImage(tableData.go_out_image)"
                :z-index="9000" :key="index" :src="item" :preview-src-list="formatImage(tableData.go_out_image)">
                <div slot="error" class="image-slot">
                  <i class="iconfont lebo-picerr"></i>
                  <span>加载失败</span>
                </div>
              </el-image>
            </div>
            <div v-else class="image-slot">
              <span>{{
                tableData.go_out_time === "0001-01-01T00:00:00+08:05"
                ? "车辆暂未出场"
                : "暂无出场图片"
              }}</span>
            </div>
            <div class="maskBox">出场图片</div>
          </el-col>
        </el-row>

      </el-form>
      <lbButton type="goBack" icon="back" class="checkButton2"
        v-if="tableData.final_cost > 0 && tableData.order_status === 2 && tableData.payment_method != 3 && tableData.payment_method !== 5 && (isShowBtn('3e4b6be89df349498f5aa529') || isShowBtn('eca0dec62b1a4e199b1d8aa7'))"
        @click="defaultForm">返 回</lbButton>
      <lbButton type="orign" icon="confirm"
        v-if="tableData.final_cost > 0 && tableData.order_status === 2 && tableData.payment_method != 3 && tableData.payment_method !== 5 && (isShowBtn('3e4b6be89df349498f5aa529') || isShowBtn('eca0dec62b1a4e199b1d8aa7'))"
        size="small" @click="dialogFormVisible = true" class="checkButton">退 款</lbButton>
    </div>
    <lebo-dialog :close-on-click-modal="false" append-to-body title="退款说明" :isShow="dialogFormVisible" width="35%"
      @close="dialogFormVisible = false" footerSlot>
      <el-form v-if="dialogFormVisible" :model="queryRefund" :rules="refundFormRules" ref="refundref" label-width="120px">
        <el-form-item label="退款金额：" prop="refundMoney" style="text-align: start">
          <span>￥{{ queryRefund.refundMoney }}</span>
        </el-form-item>
        <el-form-item label="退款原因：" prop="apply_refund_reason">
          <el-input :value="queryRefund.apply_refund_reason" maxlength="150" placeholder="请输入退款原因" rows="5"
            type="textarea" @input="(e) => (queryRefund.apply_refund_reason = validSpace(e))"></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <lbButton type="goBack" icon="back" @click="dialogFormVisible = false">返 回</lbButton>
        <lbButton type="orign" icon="confirm" @click="sureRefund">保 存</lbButton>
      </div>
    </lebo-dialog>
  </div>
</template>

<script>
import request from '@/api/orderApi'
import { mapGetters, mapMutations, mapState } from 'vuex'
export default {
  props: ['id'],
  data () {
    return {
      // 查询表单
      queryForm: {
        parkingLot: '湘A99999',
        licenseplate: '',
        parkingNumber: '',
        type: '',
        admissionTime: '',
        playingTime: ''
      },
      // 表格数据
      tableData: {},
      order_id: '',
      dialogFormVisible: false,
      queryRefund: {
        refundMoney: '',
        apply_refund_reason: ''
      },
      refundFormRules: {
        refundMoney: [{ required: true, trigger: 'blur' }],
        apply_refund_reason: [
          { required: true, message: '请输入退款原因', trigger: 'blur' }
        ]
      }
    }
  },
  created () {
    this.order_id = this.id
    this.requestOrderDetail({ order_id: this.order_id })
  },
  mounted () { },
  computed: {
    ...mapGetters(['getWayName']),
    ...mapState('menuList', ['isRoutW'])
  },
  methods: {
    ...mapMutations(['setQueryStatus']),
    async requestOrderDetail (data) {
      try {
        const res = await request.orderDetail(data)
        console.log('==--==--==res=', res)
        this.tableData = res.Data
        this.queryRefund.refundMoney = res.Data.final_cost.toFixed(2)
      } catch (error) { }
    },
    // goback () {
    //   this.setQueryStatus(2)
    //   if (window.history.length > 0) {
    //     this.$router.go(-1)
    //   } else {
    //     this.$router.push('/')
    //   }
    // },
    sureRefund () {
      this.$refs.refundref.validate((valid) => {
        if (valid) {
          this.dialogFormVisible = false
          this.refund()
        } else {
          return false
        }
      })
    },
    async refund () {
      const { apply_refund_reason = '' } = this.queryRefund
      const res = await request.applyRefund({
        order_id: this.id,
        apply_refund_reason: apply_refund_reason
      })
      if (res && res.Code === 200) {
        this.$msg.success('恭喜退款成功')
        this.requestOrderInfo()
      }
    },
    defaultForm () {
      this.$emit('closeDialog', false)
    }
  }
}
</script>

<style scoped lang="less">
.title {
  width: 100%;
  font-size: 18px;
  font-weight: 700;
  text-align: left;
  color: #000;
  // margin-top: 20px;
}

.detailForm {
  max-height: 650px;
  overflow: hidden;
  overflow-y: auto;

  .maskBox {
    width: 100%;
    height: 30px;
    line-height: 30px;
    font-size: 12px;
    font-family: Source Han Sans CN-Regular, Source Han Sans CN;
    font-weight: 400;
    color: white;
    text-align: center;
    background-color: rgba(0,0,0,.5);
    margin-bottom: 10px;
  }

  /deep/ .el-form-item {
    margin-bottom: 0;
  }

  /deep/ .el-form-item__content {
    text-align: left;
  }
}

// .el-input__inner {
//   height: 36px;
//   line-height: 36px;
// }
.button_box {
  display: flex;
}

.button_box .el-button {
  margin-left: 15px;
  width: auto;
}

.el-dialog__body {
  padding: 20px;
}

.el-button {
  width: auto !important;
}

.imagefu img {
  width: 100%;
  height: auto;
  max-width: 100%;
  // max-height: 100%;
}

.itemone {
  display: flex;
  justify-content: flex-end;
}

.itemtwo {
  display: flex;
  justify-content: flex-start;
}

.in-out-image {
  width: 100%;
  height: 200px;
  display: flex;
  align-items: center;
  justify-content: center;

  // background-color: #ccc;
  /deep/ .image-slot {
    width: 100%;
    height: 200px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    background-color: #f5f7fa;
    color: #999;
    .iconfont{
      font-size: 36px;
    }
  }

  /deep/ .el-image__inner {
    max-width: 100%;
    // max-height: 100%;
    width: auto;
    height: auto;
  }
}

.image-slot {
  width: 100%;
  height: 200px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background-color: #f5f7fa;
  color: #999;
  .iconfont{
      font-size: 36px;
    }
}

.el-carousel {
  width: 100%;
  height: 200px;

  /deep/ .el-carousel__container {
    position: relative;
    height: 200px;
  }

  /deep/ .el-carousel__button {
    background-color: #027dfc !important;
  }

  /deep/ .el-carousel__indicator--horizontal {
    padding: 4px 4px;
  }
}

.box-card {
  position: relative;
  padding-bottom: 30px;
  margin-top: 0 !important;

  .checkButton {
    position: absolute;
    right: 0px;
  }

  .checkButton2 {
    position: absolute;
    right: 100px;
  }
}
</style>
