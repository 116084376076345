<template>
  <!-- 订单管理 —— 订单信息 -->
  <div class="box-card">
    <div style="text-align: left">
      <!-- 查询表单 -->
      <lebo-query :isMultiRow="true" @toggleChange="toggleChange" class="queryBox">
        <el-form :inline="true" size="small" label-position="right" class="query_form" :model="queryForm"
          @submit.native.prevent>
          <el-row>
            <el-col :span="24">
              <el-form-item label="停车场：">
                <el-select popper-class="my-select" clearable v-model="queryForm.parking_id" filterable
                  default-first-option placeholder="请选择停车场" @change="onSubmit">
                  <el-option v-for="item in parkingList" :key="item.parking_id" :label="item.parking_name"
                    :value="item.parking_id">
                  </el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="车牌号：">
                <el-input maxlength="8" placeholder="请输入车牌号" :value="queryForm.plate_number" @input="(e) => (queryForm.plate_number = validPlatetoUpperCase(e))
                  " @keyup.enter.native="onSubmit"></el-input>
              </el-form-item>
              <el-form-item label="订单号：">
                <el-input maxlength="30" placeholder="请输入订单号" :value="queryForm.order_no"
                  @input="(e) => (queryForm.order_no = validSpace(e))" @keyup.enter.native="onSubmit"></el-input>
              </el-form-item>
              <el-form-item label="订单来源：">
                <el-select popper-class="my-select" size="small" clearable v-model="queryForm.order_source"
                  placeholder="请选择订单来源" @clear="queryForm.order_source = null" @change="onSubmit">
                  <el-option v-for="item in getOptions('OrderSource')" :key="item.value" :label="item.name"
                    :value="item.value"></el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="订单状态：">
                <el-select popper-class="my-select" size="small" clearable v-model="queryForm.order_status"
                  placeholder="请选择订单状态" @clear="queryForm.order_status = null" @change="onSubmit">
                  <el-option v-for="item in getOptions('OrderStatus')" :key="item.value" :label="item.name"
                    :value="item.value"></el-option>
                </el-select>
              </el-form-item>
              <el-form-item v-if="!isMultiRow">
                <lbButton icon="chaxun" :fill="true" @click="onSubmit()">查询</lbButton>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row v-if="isMultiRow">
            <el-col :span="24">
              <el-form-item label="支付方式：">
                <el-select popper-class="my-select" clearable v-model="queryForm.payment_method" placeholder="请选择支付方式"
                  @clear="queryForm.payment_method = null" @change="onSubmit">
                  <el-option v-for="item in getOptions('PaymentMethod')" :key="item.value" :label="item.name"
                    :value="item.value"></el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="创建时间：">
                <el-date-picker v-model="timeduan" value-format="yyyy-MM-dd HH:mm:ss" type="datetimerange"
                  :default-time="['00:00:00', '23:59:59']" @blur="onSubmit" range-separator="至"
                  start-placeholder="订单生成开始日期" end-placeholder="订单生成结束日期">
                </el-date-picker>
              </el-form-item>
              <el-form-item label="订单支付时间：">
                <el-date-picker v-model="timeduan2" value-format="yyyy-MM-dd HH:mm:ss" type="datetimerange"
                  :default-time="['00:00:00', '23:59:59']" @blur="onSubmit" range-separator="至"
                  start-placeholder="订单支付开始日期" end-placeholder="订单支付结束日期">
                </el-date-picker>
              </el-form-item>
              <el-form-item v-if="isMultiRow">
                <lbButton icon="chaxun" :fill="true" @click="onSubmit()">查询</lbButton>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
      </lebo-query>
      <le-card title="订单信息">
        <template slot="heard-left">
          <leCount type="one" icon="lebo-yingshou" title="应收" hint="按订单生成时间统计" :count="calculateCost"></leCount>
          <leCount type="three" icon="lebo-youhui" title="优惠" :count="discount_amount" hint="按订单支付时间统计"></leCount>
          <leCount type="two" icon="lebo-shishou" title="实收" :count="finalCost" hint="按订单支付时间统计"></leCount>
          <leCount type="four" icon="lebo-tuikuan1" title="退款" :count="refundCost" hint="按订单退款时间统计"></leCount>
        </template>
        <template slot="heard-right">
          <div class="right">
            <!-- <el-button  size="small" type="danger"  @click="batchDel" >删除</lbButton> -->
            <lbButton type="warning" icon="daochu" @click="exportFile" v-if="isShowBtn('7e20c440aa824d3fb54a3fa5') ||
              isShowBtn('0c82717fbc39473bbd7d40d9')
              ">导出</lbButton>
          </div>
        </template>
        <div>
          <template slot="heard-right"></template>
          <!-- 表格 -->
          <!-- 使用插件生产表格 -->
          <!-- <avue-crud :data="tableData" :option="option" :page.sync="page" @size-change="sizeChange"
            @current-change="currentChange">
            <template slot-scope="{row}" slot="parking_time_length">
              <span>{{ getTimeLength(row.parking_time_length) }}</span>
            </template>
            <template slot-scope="{row}" slot="calculate_cost">
              <span>{{ "￥" + row.calculate_cost.toFixed(2) }}</span>
            </template>
            <template slot-scope="{row}" slot="discount_amount">
              <span>{{ "￥" + row.discount_amount.toFixed(2) }}</span>
            </template>
            <template slot-scope="{row}" slot="final_cost">
              <span>￥ {{ row.final_cost.toFixed(2) }}</span>
            </template>
            <template slot-scope="{row}" slot="payment_method">
              <span>{{
                row.payment_method === 0
                ? "/"
                : getWayName("PaymentMethod", row.payment_method)
              }}
              </span>
            </template>
            <template slot-scope="{row}" slot="order_source">
              <span>{{
                getWayName("OrderSource", row.order_source)
              }}</span>
            </template>
            <template slot-scope="{row}" slot="order_status">
              <span :style="getFontColor(row.order_status)">{{
                getWayName("OrderStatus", row.order_status)
              }}</span>
            </template>
            <template slot-scope="{row}" slot="create_time">
              <span>{{ validDateTime(row.create_time) }}</span>
            </template>
            <template slot-scope="{row}" slot="menu">
              <lbButton type="succes" icon="chakan" hint="查看" @click="find(row)"></lbButton>
              <lbButton type="err" icon="tuikuan1" hint="退款" @click="refund(row)" v-if="row.final_cost > 0 &&
                row.order_status === 2 &&
                row.payment_method !== 3 &&
                row.payment_method !== 5 &&
                (isShowBtn('3e4b6be89df349498f5aa529') ||
                  isShowBtn('eca0dec62b1a4e199b1d8aa7'))
                "></lbButton>
              <lbButton type="setting" icon="zimudingdan" hint="子母订单" @click="showOrder(row)" v-if="row.is_letter_order">
              </lbButton>
            </template>
          </avue-crud> -->
          <el-table class="tablestyle" ref="multipleTable" :data="tableData" align="center" header-align="center"
            tooltip-effect="dark" border stripe>
            <el-table-column prop="order_no" label="订单号" width="150"></el-table-column>
            <el-table-column prop="plate_number" label="车牌号" width="130"></el-table-column>
            <el-table-column prop="parking_name" label="停车场" min-width="120"></el-table-column>
            <el-table-column label="停车时长">
              <template slot-scope="scope">
                <span>{{ getTimeLength(scope.row.parking_time_length) }}</span>
              </template>
            </el-table-column>
            <el-table-column label="应收金额" >
              <template slot-scope="scope">
                <span>{{ "￥" + scope.row.calculate_cost.toFixed(2) }}</span>
              </template>
            </el-table-column>
            <el-table-column label="优惠金额" >
              <template slot-scope="scope">
                <span>{{ "￥" + scope.row.discount_amount.toFixed(2) }}</span>
              </template>
            </el-table-column>
            <el-table-column label="实收金额" >
              <template slot-scope="scope">
                <span>￥ {{ scope.row.final_cost.toFixed(2) }}</span>
              </template>
            </el-table-column>

            <el-table-column label="支付方式" >
              <template slot-scope="scope">
                <span>{{
                  scope.row.payment_method === 0
                  ? "/"
                  : getWayName("PaymentMethod", scope.row.payment_method)
                }}
                </span>
              </template>
            </el-table-column>
            <el-table-column label="订单来源" >
              <template slot-scope="scope">
                <span>{{
                  getWayName("OrderSource", scope.row.order_source)
                }}</span>
              </template>
            </el-table-column>
            <el-table-column label="订单状态">
              <template slot-scope="scope">
                <span :style="getFontColor(scope.row.order_status)">{{
                  getWayName("OrderStatus", scope.row.order_status)
                }}</span>
              </template>
            </el-table-column>
            <el-table-column label="订单生成时间" width="150">
              <template slot-scope="scope">
                <span>{{ validDateTime(scope.row.create_time) }}</span>
              </template>
            </el-table-column>
            <el-table-column label="操作" width="180">
              <template slot-scope="scope">
                <lbButton type="succes" icon="chakan" hint="查看" @click="find(scope.row)"></lbButton>
                <lbButton type="err" icon="tuikuan1" hint="退款" @click="refund(scope.row)" v-if="scope.row.final_cost > 0 &&
                  scope.row.order_status === 2 &&
                  scope.row.payment_method !== 3 &&
                  scope.row.payment_method !== 5 &&
                  (isShowBtn('3e4b6be89df349498f5aa529') ||
                    isShowBtn('eca0dec62b1a4e199b1d8aa7'))
                  "></lbButton>
                <lbButton type="setting" icon="zimudingdan" hint="子母订单" @click="showOrder(scope.row)"
                  v-if="scope.row.is_letter_order"></lbButton>
              </template>
            </el-table-column>
          </el-table>
          <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
            :current-page="queryForm.PageIndex" :page-sizes="[10, 20, 30, 40]" :page-size="queryForm.PageSize"
            layout="total, sizes, prev, pager, next, jumper" :total="TotalCount"></el-pagination>
        </div>
      </le-card>
    </div>
    <lebo-dialog append-to-body title="退款说明" :isShow="dialogFormVisible" width="35%" @close="dialogFormVisible = false"
      footerSlot>
      <el-form v-if="dialogFormVisible" :model="queryRefund" label-width="120px" :rules="refundFormRules" ref="refundref">
        <el-form-item label="退款金额：" prop="refundMoney">
          <span>￥{{ queryRefund.refundMoney }}</span>
        </el-form-item>
        <el-form-item label="退款原因：" prop="apply_refund_reason">
          <el-input style="width: 90%" :value="queryRefund.apply_refund_reason" maxlength="150" placeholder="请输入退款原因"
            rows="6" type="textarea" @input="(e) => (queryRefund.apply_refund_reason = validSpace(e))"></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <!-- <lbButton type="goBack" icon="back" @click="cancleRefund">返 回</lbButton> -->
        <lbButton type="default" fill icon="confirm" @click="sureRefund">保 存</lbButton>
      </div>
    </lebo-dialog>
    <!-- 子母订单 -->
    <lebo-dialog append-to-body title="子母订单" :isShow="showOrderDialog" width="60%" style="height: 80%" closeOnClickModal
      @close="showOrderDialog = false" footerSlot>
      <div v-if="showOrderDialog">
        <el-table class="tablestyle" :data="ccOrder" border>
          <el-table-column type="index" label="序号" width="50"></el-table-column>
          <el-table-column property="order_no" label="订单号"></el-table-column>
          <el-table-column property="plate_number" label="车牌号"></el-table-column>
          <el-table-column prop="parking_name" label="停车场" ></el-table-column>
          <el-table-column property="parking_time_length" label="停车时长">
            <template slot-scope="scope">
              <span>{{ getTimeLength(scope.row.parking_time_length) }}</span>
            </template>
          </el-table-column>
          <el-table-column label="实收金额">
            <template slot-scope="scope">
              <span>￥{{ scope.row.final_cost }}</span>
            </template>
          </el-table-column>
          <el-table-column label="订单状态">
            <template slot-scope="scope">
              <span :style="getFontColor(scope.row.order_status)">{{
                getWayName("OrderStatus", scope.row.order_status)
              }}</span>
            </template>
          </el-table-column>
          <el-table-column label="订单生成时间">
            <template slot-scope="scope">
              <span>{{ validDateTime(scope.row.create_time) }}</span>
            </template>
          </el-table-column>
        </el-table>
        <span class="amountBox">实收金额汇总：￥{{ allTotal }}</span>
      </div>
    </lebo-dialog>
    <!-- 导出时间选择对话框 -->
    <lebo-dialog title="导出记录" :isShow="derivedSelection" width="560px" class="derivedSelectionDialog"
      @close="derivedSelection = false" footerSlot>
      时间： <el-date-picker v-model="derivedmonth" type="month" placeholder="选择月">
      </el-date-picker>
      <div slot="footer" class="dialog-footer">
        <!-- <lbButton type="goBack" icon="back" @click="derivedSelection = false">返 回</lbButton> -->
        <lbButton type="default" fill icon="confirm" @click="submissionDate">导 出</lbButton>
      </div>
    </lebo-dialog>
    <!-- 查看订单数据 -->
    <lebo-dialog :isShow="showOrderInfo" width="70%" title="查看订单" :footerSlot="true" @close="showOrderInfo = false"
      closeOnClickModal>
      <seeOrderInfo :id="dialogOrderId" @closeDialog="closeOrderInfo" v-if="showOrderInfo"></seeOrderInfo>
    </lebo-dialog>
  </div>
</template>

<script>
import request from '@/api/orderApi'
import { mapActions, mapState, mapGetters, mapMutations } from 'vuex'
import seeOrderInfo from './components/seeOrderInfo/index.vue'
export default {
  components: { seeOrderInfo },
  data () {
    return {
      // 查询表单
      queryForm: {
        parking_id: null,
        plate_number: '',
        payment_method: null,
        order_source: null,
        order_status: null,
        PageSize: 10,
        PageIndex: 1,
        order_no: '',
        datetime_start: '',
        datetime_end: '',
        query_type: 0, // 查询类型 0：分页查询 1：导出功能查询
        payment_start: '',
        payment_end: ''
      },
      dialogForm: {
        parking_record_id: null
      },
      timeduan: [],
      timeduan2: [],
      // 表格数据
      tableData: [],
      TotalCount: 0,
      calculateCost: 0.0, // 应收统计
      finalCost: 0.0, // 实收统计
      refundCost: 0.0, // 退款统计
      discount_amount: 0.0, // 优惠金额
      dialogFormVisible: false,
      queryRefund: {
        refundMoney: '',
        order_id: '',
        apply_refund_reason: ''
      },
      refundFormRules: {
        refundMoney: [{ required: true, trigger: 'blur' }],
        apply_refund_reason: [
          { required: true, message: '请输入退款原因', trigger: 'blur' }
        ]
      },
      showOrderDialog: false,
      // 字母订单数据
      ccOrder: [],
      derivedSelection: false,
      derivedmonth: '', // 导出日期选择
      beginQueryTime: '', // 导出开始时间
      endQueryTime: '', // 导出结束时间
      showOrderInfo: false, // 查看订单数据
      dialogOrderId: '',
      isMultiRow: true, // 查询下拉弹窗
      // 表格插件配置项
      option: {
        searchMenuSpan: 8,
        header: false,
        delBtn: false, // 行删除按钮
        editBtn: false, // 编辑按钮
        menuAlign: 'left', // 属性对齐方式
        menuHeaderAlign: 'left', // 表头对齐方式
        menu: true,
        menuFixed: false, // 解除操作框冻结
        tip: false,
        column: [
          {
            label: '订单号',
            prop: 'order_no',
            width: 150
          }, {
            label: '车牌号',
            prop: 'plate_number'
          }, {
            label: '停车场',
            prop: 'parking_name'
          }, {
            label: '停车时长',
            prop: 'parking_time_length',
            slot: true
          }, {
            label: '应收金额',
            prop: 'calculate_cost',
            slot: true
          }, {
            label: '优惠金额',
            prop: 'discount_amount',
            slot: true
          }, {
            label: '实收金额',
            prop: 'final_cost',
            slot: true
          }, {
            label: '支付方式',
            prop: 'payment_method',
            slot: true
          }, {
            label: '订单来源',
            prop: 'order_source',
            slot: true
          }, {
            label: '订单状态',
            prop: 'order_status',
            slot: true
          }, {
            label: '订单生成时间',
            prop: 'create_time',
            slot: true,
            width: 150
          }
        ]
      },
      // 分页器
      page: {
        background: false,
        total: 0,
        pageSize: 10,
        currentPage: 1
      }
    }
  },
  watch: {
    timeduan (newV, oldV) {
      if (newV) {
        this.queryForm.datetime_start = newV[0]
        this.queryForm.datetime_end = newV[1]
      } else {
        this.queryForm.datetime_start = ''
        this.queryForm.datetime_end = ''
        this.onSubmit()
      }
    },
    timeduan2 (newV, oldV) {
      if (newV) {
        this.queryForm.payment_start = newV[0]
        this.queryForm.payment_end = newV[1]
      } else {
        this.queryForm.payment_start = ''
        this.queryForm.payment_end = ''
        this.onSubmit()
      }
    }
  },
  created () {
    if (this.queryStatus === 2) {
      this.queryForm = this.deepClone(this.queryObj)
      this.timeduan2 = [
        this.queryForm.payment_start,
        this.queryForm.payment_end
      ]
      this.timeduan = [
        this.queryForm.datetime_start,
        this.queryForm.datetime_end
      ]
      // console.log(this.queryForm);
    }
    this.requestOrderInfo()
  },
  computed: {
    ...mapState(['parkingList', 'queryObj', 'queryStatus']),
    ...mapState('menuList', ['isRoutW']),
    ...mapGetters(['getOptions', 'getWayName']),
    allTotal () {
      if (this.ccOrder != null && this.ccOrder.length > 0) {
        const res = this.ccOrder.reduce((temp, item, index, data) => {
          console.log(temp, item.final_cost)
          return temp + (item.final_cost - 0)
        }, 0)
        return res.toFixed(2)
      }
      return '0.00'
    }
  },
  mounted () {
    window.addEventListener('beforeunload', (e) => this.beforeunloadFn(e))
    // this.requestPorkingList();
    // this.requestFindList();
  },
  beforeRouteLeave (to, from, next) {
    this.setQueryObj(this.queryForm)
    this.setQueryStatus(1)
    next()
  },
  destroyed () {
    window.removeEventListener('beforeunload', (e) => this.beforeunloadFn(e))
  },
  methods: {
    ...mapActions(['requestPorkingList', 'requestFindList']),
    ...mapMutations(['setQueryObj', 'setQueryStatus']),
    async showOrder (item) {
      console.log(item)
      this.dialogForm = {
        parking_record_id: item.parking_record_id
      }
      this.requestListByLetterOrder()
    },
    async requestListByLetterOrder () {
      const res = await request.getListByLetterOrder(this.dialogForm)
      if (res.Code === 200) {
        this.ccOrder = res.Data
        this.showOrderDialog = true
      }
    },
    beforeunloadFn (e) {
      this.setQueryStatus(1)
    },
    // 查询
    onSubmit () {
      this.queryForm.PageIndex = 1
      this.requestOrderInfo()
    },
    // 每页条数改变
    handleSizeChange (size) {
      this.queryForm.PageSize = size
      this.requestOrderInfo()
    },
    // // 每页条数改变
    // sizeChange (val) {
    //   this.page.currentPage = 1
    //   this.page.pageSize = val
    //   this.queryForm.PageSize = val
    //   this.requestOrderInfo()
    // },
    // 页码改变
    handleCurrentChange (page) {
      this.queryForm.PageIndex = page
      this.requestOrderInfo()
      this.$refs.multipleTable.bodyWrapper.scrollTop = 0
    },
    // // 页码改变
    // currentChange (val) {
    //   this.queryForm.PageIndex = val
    //   this.page.currentPage = val
    //   this.requestOrderInfo()
    // },
    // 设置订单状态字体颜色
    getFontColor (type) {
      // 1: 待支付
      // 2: 已支付
      // 3: 退款中
      // 4: 已退款
      // 5: 退款失败
      switch (type) {
        case 1:
        case 5:
          return 'color: #ee0000;'
        case 2:
          return 'color: #67C23A;'
        case 3:
        case 4:
          return 'color: #606266;'
      }
    },
    find (row) {
      this.showOrderInfo = true
      this.dialogOrderId = row._id
      // var preStr = this.isRoutW ? 'routw-' : ''
      // this.$router.push({
      //   path: `/${preStr}order-info-look/${row._id}`
      // })
    },
    cancleRefund () {
      this.dialogFormVisible = false
      this.queryRefund = {
        refundMoney: '',
        order_id: '',
        apply_refund_reason: ''
      }
    },
    sureRefund () {
      this.$refs.refundref.validate((valid) => {
        if (valid) {
          this.requestApplyRefund()
        } else {
          return false
        }
      })
    },
    refund (row) {
      // console.log(row);
      this.queryRefund.refundMoney = row.final_cost.toFixed(2)
      this.queryRefund.order_id = row._id
      this.dialogFormVisible = true
    },
    async requestOrderInfo () {
      var that = this
      const params = {
        parking_id: that.queryForm.parking_id,
        plate_number: that.queryForm.plate_number,
        payment_method: that.queryForm.payment_method
          ? that.queryForm.payment_method
          : 0,
        order_source: that.queryForm.order_source
          ? that.queryForm.order_source
          : 0,
        order_status: that.queryForm.order_status
          ? that.queryForm.order_status
          : 0,
        PageSize: that.queryForm.PageSize,
        PageIndex: that.queryForm.PageIndex,
        order_no: that.queryForm.order_no,
        datetime_start: that.queryForm.datetime_start,
        datetime_end: that.queryForm.datetime_end,
        query_type: that.queryForm.query_type,
        payment_start: that.queryForm.payment_start,
        payment_end: that.queryForm.payment_end
      }
      const res = await request.orderInfoList(params)
      if (res && res.Code === 200) {
        this.tableData = res.Data.DataList
        this.TotalCount = res.Data.TotalCount
        this.calculateCost = res.Data.calculate_cost
        this.finalCost = res.Data.final_cost
        this.refundCost = res.Data.refund_cost
        this.discount_amount = res.Data.discount_amount
      }
      this.page.total = res && res.Code === 200 && res.Data ? res.Data.TotalCount : 0
    },
    async requestApplyRefund () {
      const { order_id = '', apply_refund_reason = '' } = this.queryRefund
      const res = await request.applyRefund({
        order_id: order_id,
        apply_refund_reason: apply_refund_reason
      })
      if (res && res.Code === 200) {
        this.$msg.success('恭喜退款成功')
        this.requestOrderInfo()
      }
      this.queryRefund = {
        refundMoney: '',
        order_id: '',
        apply_refund_reason: ''
      }
      this.dialogFormVisible = false
    },
    // 导出
    exportFile () {
      this.derivedSelection = true
      this.derivedmonth = new Date(Date.now())
    },
    formatJson (filterVal, jsonData) {
      return jsonData.map((v) =>
        filterVal.map((j) => {
          var map = {}
          this.$set(map, j, v[j])
          return this.formatter(map, { property: j })
          // 为formatter专门为了码表渲染的组件
        })
      )
    },
    // 数据格式化
    formatter (row, column) {
      // 日期处理
      // console.log(column);
      if (column.property === 'create_time') {
        return this.$moment(row[column.property]).format('YYYY-MM-DD HH:mm:ss')
      } else if (column.property === 'parking_time_length') {
        return this.getTimeLength(row[column.property])
      } else if (column.property === 'payment_method') {
        return this.getWayName('PaymentMethod', row[column.property])
          ? this.getWayName('PaymentMethod', row[column.property])
          : '/'
      } else if (column.property === 'order_source') {
        return this.getWayName('OrderSource', row[column.property])
      } else if (column.property === 'order_status') {
        return this.getWayName('OrderStatus', row[column.property])
      } else {
        return row[column.property]
      }
    },
    // 选择导出日期确定触发
    async submissionDate () {
      const selectYear = this.derivedmonth.getFullYear()
      const selectMonth = (this.derivedmonth.getMonth() + 1 < 10 ? '0' + (this.derivedmonth.getMonth() + 1) : this.derivedmonth.getMonth() + 1)
      this.beginQueryTime = selectYear + '-' + selectMonth + '-01 00:00:00'
      if (selectMonth === '01' || selectMonth === '03' || selectMonth === '05' || selectMonth === '07' || selectMonth === '08' || selectMonth === '10' || selectMonth === '12') {
        this.endQueryTime = selectYear + '-' + selectMonth + '-31 23:59:59'
      } else if (selectMonth === '04' || selectMonth === '06' || selectMonth === '09' || selectMonth === '11') {
        this.endQueryTime = selectYear + '-' + selectMonth + '-30 23:59:59'
      } else if (selectMonth === '02' && selectYear % 4 === 0) {
        this.endQueryTime = selectYear + '-' + selectMonth + '-29 23:59:59'
      } else {
        this.endQueryTime = selectYear + '-' + selectMonth + '-28 23:59:59'
      }
      // console.log('查询时间', this.queryForm.datetime_start)
      const res = await request.orderInfoList({
        parking_id: this.queryForm.parking_id,
        plate_number: this.queryForm.plate_number,
        payment_method: this.queryForm.payment_method
          ? this.queryForm.payment_method
          : 0,
        order_source: this.queryForm.order_source
          ? this.queryForm.order_source
          : 0,
        order_status: this.queryForm.order_status
          ? this.queryForm.order_status
          : 0,
        order_no: this.queryForm.order_no,
        // datetime_start: this.queryForm.datetime_start,
        datetime_start: this.beginQueryTime, // 查询开始时间
        datetime_end: this.endQueryTime,
        query_type: 1,
        payment_start: this.queryForm.payment_start,
        payment_end: this.queryForm.payment_end
      })
      console.log(res)
      const resultList = res.Data.DataList
      if (resultList.length <= 0) {
        this.$msg.warning('暂无数据！')
      } else {
        resultList.forEach((item) => {
          item.total_calculate_cost = res.Data.calculate_cost
          item.total_final_cost = res.Data.final_cost
          item.total_discount_amount = res.Data.discount_amount
          item.total_refund_cost = res.Data.refund_cost
        })
        // 设置当前日期
        var name = this.$moment(new Date()).format('YYYY-MM-DD')
        // console.log(name);
        import('@/utils/Export2Excel2').then((excel) => {
          const tHeader = [
            [
              '订单号',
              '车牌号',
              '停车场',
              '停车时长',
              '应收金额',
              '实收金额',
              '优惠金额',
              '支付方式',
              '订单来源',
              '订单状态',
              '订单生成时间',
              '合计',
              '',
              '',
              ''
            ],
            [
              '',
              '',
              '',
              '',
              '',
              '',
              '',
              '',
              '',
              '',
              '',
              '应收',
              '实收',
              '优惠',
              '退款'
            ]
          ]
          const filterVal = [
            'order_no',
            'plate_number',
            'parking_name',
            'parking_time_length',
            'calculate_cost',
            'final_cost',
            'discount_amount',
            'payment_method',
            'order_source',
            'order_status',
            'create_time',
            'total_calculate_cost',
            'total_final_cost',
            'total_discount_amount',
            'total_refund_cost'
          ]
          // const list = resultList;
          const data = this.formatJson(filterVal, resultList)
          // console.log(data);
          var merges = [
            'A1:A2',
            'B1:B2',
            'C1:C2',
            'D1:D2',
            'E1:E2',
            'F1:F2',
            'G1:G2',
            'H1:H2',
            'I1:I2',
            'J1:J2',
            'K1:K2',
            'L1:O1',
            `L3:L${data.length + 2}`,
            `M3:M${data.length + 2}`,
            `N3:N${data.length + 2}`,
            `O3:O${data.length + 2}`
          ]
          excel.export_json_to_excel({
            multiHeader: tHeader,
            // header: tHeader, //  必填   导出数据的表头
            data, //  必填   导出具体数据
            filename: '订单报表' + name, //  非必填   导出文件名
            merges: merges, // 合并单元格
            autoWidth: true, //  非必填   单元格是否自动适应宽度
            bookType: 'xlsx' //  非必填   导出文件类型
          })
        })
      }
      this.derivedSelection = false
      this.derivedmonth = ''
    },
    // 关闭查看订单
    closeOrderInfo () {
      this.showOrderInfo = false
    },
    // 下拉查询
    toggleChange (flag) {
      // console.log(flag)
      this.isMultiRow = flag
    }

  }
}
</script>

<style scoped lang="less">
// .el-input__inner {
//   height: 36px;
//   line-height: 36px;
// }
.box-card {
  margin-top: 20px;

  .queryBox {
    margin-bottom: 20px;
  }
}

.button_box {
  display: flex;
}

.button_box .el-button {
  margin-left: 15px;
  width: auto;
}

.el-dialog__body {
  padding: 20px;
}

.el-button {
  width: auto !important;
}

.btnTempate {
  margin-bottom: 8px;
}

.query_box {
  display: flex;
  align-items: center;
  justify-content: space-between;

  .query_form {
    flex: 1;

    /deep/ .el-form-item__content {
      .el-select.el-select--small {
        width: 160px;

        .el-input.el-input--small {
          width: 100% !important;
        }
      }
    }
  }
}

.tablestyle {
  /deep/ .el-table__body-wrapper {
    max-height: 480px;
  }
}

.amountBox {
  display: flex;
  justify-content: end;
  margin-top: 20px;
}

/deep/.derivedSelectionDialog {
  .el-dialog__header {
    text-align: left;
  }

  .el-dialog__body {
    text-align: center;
  }
}

/deep/.el-dialog__body {
  text-align: center;
}

// 列表最大高度
/deep/.el-table .el-table__body-wrapper {
  max-height: 490px !important;
}
/deep/.el-form.query_form.el-form--inline .el-form-item.el-form-item--small .el-form-item__content .el-date-editor{
  width: 450px!important;
}
/deep/.el-form.query_form.el-form--inline .el-form-item.el-form-item--small .el-form-item__content .el-input.el-input--small{
  width: 175px;
}
</style>
